<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="退款原因" prop="refundReason" >
        <a-input v-model="form.refundReason" placeholder="请输入退款原因" disabled/>
      </a-form-model-item>
      <a-form-model-item label="退款金额" prop="refundMoney" >
        <a-input v-model="form.refundMoney" placeholder="请输入退款金额" disabled/>
      </a-form-model-item>
      <a-form-model-item label="退款说明" prop="refundDesc" >
        <a-input v-model="form.refundDesc" placeholder="请输入内容" type="textarea" disabled />
      </a-form-model-item>
      <a-form-model-item label="退款补充证据" prop="refundImg" >
        <img-preview v-if="form.refundImg" v-for="(img,index) in form.refundImg.split(',')" :key="index" :src="img" style="width: 80px;height: 80px;margin-right: 10px"/>
        <div v-else>
          {{ '无' }}
        </div>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-radio-group v-model="form.status" >
          <a-radio :value="1">通过</a-radio>
          <a-radio :value="2">拒绝</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核备注" prop="approveContent" >
        <a-textarea v-model="form.approveContent" :row='4'/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrder, addOrder, updateOrder } from '@/api/user/orderRefund'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    ImgPreview
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        orderId: null,

        tradeNo: null,

        refundReason: null,

        refundMoney: null,

        refundDesc: null,

        refundImg: null,

        status: 1,

        type: null,

        expireTime: null,

        approveTime: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        orderId: null,
        tradeNo: null,
        refundReason: null,
        refundMoney: null,
        refundDesc: null,
        refundImg: null,
        status: 1,
        type: null,
        expireTime: null,
        approveTime: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOrder({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.status = 1
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrder(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrder(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
