import request from '@/utils/request'


// 查询订单退款售后列表
export function listOrder(query) {
  return request({
    url: '/user/order-refunds/list',
    method: 'get',
    params: query
  })
}

// 查询订单退款售后分页
export function pageOrder(query) {
  return request({
    url: '/user/order-refunds/page',
    method: 'get',
    params: query
  })
}

// 查询订单退款售后详细
export function getOrder(data) {
  return request({
    url: '/user/order-refunds/detail',
    method: 'get',
    params: data
  })
}

// 新增订单退款售后
export function addOrder(data) {
  return request({
    url: '/user/order-refunds/add',
    method: 'post',
    data: data
  })
}

// 修改订单退款售后
export function updateOrder(data) {
  return request({
    url: '/user/order-refunds/edit',
    method: 'post',
    data: data
  })
}

// 删除订单退款售后
export function delOrder(data) {
  return request({
    url: '/user/order-refunds/delete',
    method: 'post',
    data: data
  })
}
