<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="退款编号" prop="tradeNo">
                <a-input v-model="queryParam.tradeNo" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="申请人昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="申请人手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="退款状态" prop="status">
                <a-select v-model="queryParam.status" placeholder="请选择" allow-clear>
                  <a-select-option :value="0">正在审核</a-select-option>
                  <a-select-option :value="1">审核成功</a-select-option>
                  <a-select-option :value="2">审核失败</a-select-option>
                  <a-select-option :value="3">取消</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
        :scroll="{ x: 2100}"
      >
        <span slot="expireTime" slot-scope="text, record">
          {{ parseTime(record.expireTime) }}
        </span>
        <span slot="approveTime" slot-scope="text, record">
          {{ parseTime(record.approveTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="refundImg" slot-scope="text, record">
          <div v-if="record.refundImg" >
            <img-preview v-for="(img,index) in record.refundImg.split(',')" :key="index" :src="img" style="width: 50px;height: 50px;margin-right: 5px"/>
          </div>
          <div v-else>
            无
          </div>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:orderRefund:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:orderRefund:edit']">
            <a-icon type="edit" />审核
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageOrder,listOrder, delOrder } from '@/api/user/orderRefund'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'

export default {
  name: 'Order',
  components: {
    ImgPreview,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        orderId: null,
        tradeNo: null,
        refundReason: null,
        refundMoney: null,
        refundDesc: null,
        refundImg: null,
        status: null,
        type: null,
        expireTime: null,
        approveTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '申请人昵称',
          dataIndex: 'nickname',
          width: 120,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '申请人手机号',
          dataIndex: 'mobile',
          width: 120,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '支付订单编号',
          dataIndex: 'orderNo',
          width: 180,
          align: 'center'
        },
        {
          title: '退款编号',
          dataIndex: 'tradeNo',
          width: 180,
          align: 'center'
        },
        {
          title: '退款原因',
          dataIndex: 'refundReason',
          width: 150,
          align: 'center'
        },
        {
          title: '退款金额',
          dataIndex: 'refundMoney',
          width: 120,
          align: 'center'
        },
        {
          title: '退款说明',
          dataIndex: 'refundDesc',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '退款补充证据',
          dataIndex: 'refundImg',
          scopedSlots: { customRender: 'refundImg' },
          width: 200,
          align: 'center'
        },
        {
          title: '退款状态',
          dataIndex: 'status',
          width: 120,
          align: 'center',
           customRender: function (status) {
            if (status === 0) {
              return '正在审核'
            } else if (status === 1) {
              return '审核成功'
            } else if (status === 2) {
              return '审核失败'
            } else if (status === 3) {
              return '取消'
            }
          }
        },
        // {
        //   title: '退款类型',
        //   dataIndex: 'type',
        //   width: 120,
        //   align: 'center',
        //   customRender: function (type) {
        //     if (type === 0) {
        //       return '未退款'
        //     } else if (type === 1) {
        //       return '部分退款'
        //     } else if (type === 2) {
        //       return '全部退款'
        //     }
        //   }
        // },
        {
          title: '退款失效时间',
          dataIndex: 'expireTime',
          scopedSlots: { customRender: 'expireTime' },
          width: 160,
          align: 'center'
        },
        {
          title: '退款审核时间',
          dataIndex: 'approveTime',
          scopedSlots: { customRender: 'approveTime' },
          width: 160,
          align: 'center'
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '160',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询订单退款售后列表 */
    getList () {
      this.loading = true
     pageOrder(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        orderId: undefined,
        tradeNo: undefined,
        refundReason: undefined,
        refundMoney: undefined,
        refundDesc: undefined,
        refundImg: undefined,
        status: undefined,
        type: undefined,
        expireTime: undefined,
        approveTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delOrder(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/order-refund/export', {
            ...that.queryParam
          }, `订单退款售后_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
